<template src="./Layout.html"></template>
<style lang="scss" src="./Layout.scss" scoped></style>
<script>
import '@/assets/styles/base.scss'
import { doc, onSnapshot } from 'firebase/firestore'
import { mapGetters } from 'vuex'
import { logOff, getRoleName } from '../services'
import Loading from '../components/Loading/Loading'
import Menu from '../components/Menu/Menu'
import { db, firebaseAuth } from '../plugins/firebase'
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'

export default {
  name: 'Layout',
  components: {
    Loading,
    Menu
  },
  data: () => ({
    drawer: null,
    profile: false,
    maintenance: false,
    passwordModal: false,
    rules: {
      required: (v) => !!v || 'Campo requerido',
      min: (v) => (v && v.length >= 6) || 'Mínimo 6 caracteres'
    },
    password: {
      current: '',
      new: ''
    }
  }),
  created () {
    this.getParameters()
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      whatsapp: 'getWhatsapp'
    }),
    getRole: function () {
      return (rol) => getRoleName(rol)
    }
  },
  methods: {
    getParameters () {
      onSnapshot(doc(db, 'APP', 'CONFIG'), (doc) => {
        const { MAINTENANCE } = doc.data()
        this.maintenance = MAINTENANCE
      })
    },
    async signOut () {
      await logOff()
      localStorage.removeItem('auth')
      this.$store.dispatch('pushUser', null)
      this.$user = null
      this.$router.push('/login')
    },
    async changePassword () {
      if (!this.$refs.form.validate()) return

      const user = firebaseAuth.currentUser
      const credential = EmailAuthProvider.credential(
        user.email,
        this.password.current
      )

      try {
        await reauthenticateWithCredential(user, credential)
        await updatePassword(user, this.password.new)

        this.$swal({
          text: 'Contraseña actualizada con éxito.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.passwordModal = false
      } catch (error) {
        console.error('Error on change password:', error)
        if (error.code === 'auth/wrong-password') {
          this.$swal({
            text: 'Contraseña incorrecta',
            buttons: false,
            icon: 'error',
            timer: 2000
          })
        } else {
          this.$toast.error('Error: ' + error.message)
        }
      }
    }
  }
}
</script>
